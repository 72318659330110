<template>

  <div id="ProductPackageItem_body">

    <div v-if="productItemLocal">

      <table id="tbl_packages">
        <tr>
          <th>
            <div
              v-if="productItemLocal"
            >
              <div id="more_about" v-if="productItemLocal.id != 7" @click="openManual">Saber Más</div>
              <div id="extra_message">
                <h1 v-if="productItemLocal.id == 5">Copago</h1>
              </div>

              <p class="title_package">
                {{ productItemLocal.name }}
              </p>
              <lottie-animation class="lottie" :path="productItemLocal.path_animation_json"/>

              <!-- <h1>{{ productItemLocal.path_animation_json }}</h1> -->
              <h1 v-if="productItemLocal.id != 7" class="text-tertiary">Pago Único Anual</h1>
              <!-- <h1 v-if="productItemLocal.id == 7" class="text-tertiary">Pago Mensual</h1> -->

            </div>
          
          </th>

          <th v-for="(cardData, index) in productItemLocal.products"
            :key="index + '-data'"
          >
          
            <div class="thead_container">

              <!--Continental devuelve los precios en dólares americanos-->
              <div class="insurance12" v-if="productItemLocal.id === 12">
                
                <div
                  :class="[
                    productItemLocal.packageSelectedId == cardData.id
                      ? 'text-tertiary'
                      : 'text-gray-300',
                  ]"
                >

                  <h1>
                    {{ cardData.name }}
                  </h1>
                  <h1>Generales</h1>

                  <!-- Currency -->
                  <h1 v-if="productItemLocal.products[index].id == 4 || productItemLocal.products[index].id == 5 || productItemLocal.products[index].id == 6 "> $ {{ numberWithCommas(cardData.policy_premium) }} USD </h1>
                  <h1 v-else> $ {{ numberWithCommas(cardData.policy_premium) }} MXN </h1>
                </div>

              </div>

              <!--Cualquiero otro seguro vienen los precios en MXN-->
              <div v-else
                :class="[
                  productItemLocal.packageSelectedId == cardData.id
                    ? 'text-tertiary'
                    : 'text-gray-300',
                ]"
              >
                <h1>
                  {{ cardData.name }}
                </h1>

                <h1>
                  $ {{ numberWithCommas(cardData.policy_premium) }} MXN
                </h1>
              </div>

              
              <!--? Efecto Checkbox Artículo por Añadir -->
              <div
                v-show="!productItemLocal.packageInCar"
                class="
                  checkbox
                  border-2
                "
                @click="
                  productItemLocal.packageSelectedId = cardData.id;
                  updateOption(cardData.id);
                "
                :class="[
                  productItemLocal.packageSelectedId == cardData.id
                    ? ''
                    : '',
                ]"
              >
                <check-button
                  :class="[
                    productItemLocal.packageSelectedId == cardData.id
                      ? 'cursor-pointer'
                      : '',
                  ]"
                  :item="productItemLocal.id"
                  :is_selected="
                    productItemLocal.packageSelectedId == cardData.id ? true : false
                  "
                  :key="'check' + productItem.id + '-' + index"
                ></check-button>
              </div>


              <!--? Efecto Checkbox Artículo Añadido -->
              <div
                v-show="productItemLocal.packageInCar"
                :class="[
                  productItemLocal.packageSelectedId == cardData.id
                    ? 'border-grey-200'
                    : 'border-grey-200',
                ]"

                class="
                  checkbox_in_car
                  border-2
                "
              >
                <check-button
                  :class="[
                    productItemLocal.packageSelectedId == cardData.id
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed',
                  ]"
                  :item="productItemLocal.id"
                  :is_selected="
                    productItemLocal.packageSelectedId == cardData.id ? true : false
                  "
                  :key="'check' + productItemLocal.id + '-' + index"
                ></check-button>
              </div>

              


              <div class="btn_add_car" v-if="productItemLocal.packageSelectedId == cardData.id">
                
                <button v-if="!productItemLocal.packageInCar && productItemLocal"
                class="btn_item"
                @click="
                  productItemLocal.packageInCar = true;
                  addToShoopingCar(productItemLocal);
                  animateRemove(productItemLocal);
                  clickBacklogInsuranceAceptation(productItemLocal);
                "
                >
                  Añadir al carrito
                </button>
                
                <div v-else>
                  <h4>Item Agregado</h4>
                  <button
                    class="btn_item"
                    @click="
                      productItemLocal.packageInCar = false;
                      removeFromShoopingCar(productItemLocal);
                    "
                  >
                    Remover item
                  </button>
                </div>

              </div>
              
            </div>

          </th>

        </tr>

        <tr v-for="(cardData, index) in serializeComparisonPackages(productItemLocal.products)"
          :key="index + '-data'"
        >
          <td>{{ cardData.coverage.description }}</td>

          <td v-for="(price,index) in cardData.coverage.prices"
          :key="index + '-data'"
          >

            <div
              v-if="['Cantidad', 1].includes(price.covered_type) && isNaN(price.insured_amount)">
              {{ price.insured_amount }}
            </div>
            <div v-else-if="['Cantidad', 1].includes(price.covered_type) && !isNaN(price.insured_amount)" >
                ${{ numberWithCommas(price.insured_amount) }}
            </div>
            <div
              v-else-if="price.covered_type == 'Total'" >
              AMPARADA
            </div>
            <div
              v-else-if="price.covered_type == 'Excluido'" >
              NO AMPARADA
            </div>

            <div
              v-if="price.covered_type == 'Otro'"
              class="text-sm flex-1 text-center leading-4"
            >
              {{ price.insured_detail }}
            </div>

          </td>
        </tr>

        <tr>
          <div class="just_space"></div>
        </tr>
      </table>

    </div>
  </div>

</template>

<script>

  import { mapMutations, mapGetters } from "vuex";
  import CheckButton from "../check-button.vue";
  import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

  export default {

    name: "ProductPackagesItem",
    data: () => ({
      shopingItems: [],
      productCategories: [],
      productItemLocal: null,
      productSelectedAceptance: [],
      comparisonPackages: []
    }),
    components: {
      CheckButton,
      "lottie-animation":LottieAnimation
    },
    props: ["productItem"],
    computed: {
      ...mapGetters(["shoppingCar"]),
      shoppingCarItems() {
        return this.shoppingCar.getItems();
      },
    },
    mounted() {
      let self = this;
      setTimeout(function () {
        self.productItemLocal = {};
        self.productItemLocal = Object.assign(
          {},
          self.productItemLocal,
          self.productItem
        );
        self.clickBacklog("formulary_finished");
      }, 300);
    },
    methods: {
      ...mapMutations(["setShoppingCar"]),
      numberWithCommas(x) {
        return parseFloat(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      updateOption() {
        this.$emit("update-option", this.productItemLocal);
      },
      gotoNext() {},
      ...mapMutations(["removeFromShoppingList"]),
      removeFromShoopingCar(item) {
        this.shoppingCar.removeItems(item);
      },
      addToShoopingCar(item) {
        // console.log(item)
        this.shoppingCar.addItems(item);
      },
      animateRemove() {},
      openManual() {

        var category_id = this.productItemLocal.id
        var manuals_names = {
          // Accidentes Personales
          "1" : "media/Thona/Generales_Thona_AccidentesPersonales.pdf",

          // Bicicletas
          "2" : "media/Primeros_Seguros/Generales_PrimerosSeguros_Bicicleta.pdf",

          // Cáncer
          // "3" : "media/Chubb/Generales_Gmx_Mascota.pdf",

          // COVID
          "4" : "media/Thona/Generales_Thona_Covid.pdf",

          // Dental
          "5" : "media/Dentegra/Generales_Dental_Dentegra.pdf",

          // Funerario
          "6" : "media/Thona/Generales_Thona_Funerario.pdf",

          // Gastos Médicos
          // "7" : "media/Gmx/Generales_Gmx_Mascota.pdf",

          // Hogar
          "8" : "media/Chubb/Generales_Chubb_Hogar.pdf",

          // Mascotas
          "9" : "media/Gmx/Generales_Gmx_Mascota.pdf",

          // Pyme
          // "10" : "media/Chubb/Generales_Gmx_Mascota.pdf",

          // Quirúrgico
          "11" : "media/Thona/Generales_Thona_Quirurgico.pdf",

          // Accidentes en Viajes
          "12" : "media/Continental/Generales_Continental_Viajero.pdf",

          // VRIM
          "13" : "media/VRIM/Generales_VRIM_Producto.pdf",
        }

        var manual_name = manuals_names[category_id]
        var path = this.$APIServices.endpoint + manual_name
        window.open( path , '_blank' );
      },
      async clickBacklog(place) {

        var category_id = this.productItemLocal.id
        var insurances_selected_ids = [category_id]
        var insurances_selected_comments = "Formulario Inicial Finalizando"
        
        var url = this.$APIEndpoint + this.$APIServices.click_watcher
        var body = {
          "place": place,
          "comments": insurances_selected_comments,
          "category_ids":insurances_selected_ids
        }

        await this.$http.post(url , body , this.$auth
        ).then(() => {
          // console.log("Result:", result);
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          
        })

      },

      async clickBacklogInsuranceAceptation(item) {
        
        var self = this
        var place = "package_car_added"
        var categoryId = item.id
        // console.log(place)
        // console.log(categoryId)

        if(this.productSelectedAceptance.indexOf(categoryId)){
          //? No contiene el elemento el array por lo tanto no se ha enviado respuesta de producto aceptado por cliente.

          var url = this.$APIEndpoint + this.$APIServices.click_watcher
          var body = {
            "place": place,
            "comments": "Paquete Agregado al Carrito",
            "category_ids":[categoryId]
          }

          await this.$http.post(url , body , this.$auth
          ).then(() => {
            // console.log("Result:", result);
          }).catch((error) => {
            console.log(error);
          }).finally(() => {
            self.productSelectedAceptance.push(categoryId)
          });

        }     

      },

      serializeComparisonPackages( packagesData ){

        var response = []
        var coverages = []

        packagesData.forEach((packageData,index) => {

          var package_name = packageData.name

          packageData.productcoverages.forEach( (coverage) => {

            var coverage_name = coverage.name
            var insured_amount = coverage.insured_amount
            var covered_type = coverage.covered_type
            var insured_detail = coverage.insured_detail

            coverages.push({
              "package_name":package_name,
              "coverage_name":coverage_name,
              "insured_amount":insured_amount,
              "covered_type":covered_type,
              "insured_detail":insured_detail,
              "index":index
            })
          })
        })

        coverages.forEach((coverage) => {

          // console.log(coverage)

          var coverage_name = coverage.coverage_name
          var same_coverages = coverages.filter((coverage) => coverage.coverage_name == coverage_name)
          
          // Condicional se retorna la cobertura coincidencia en todos los paquetes
          if(same_coverages.length == packagesData.length){
            // console.log("same_coverages:")
            // console.log(same_coverages)
            // console.log(coverages)

            response.push({
              coverage: {
                description:coverage_name,
                prices:same_coverages
              }
            })
          } 
          // Condicional se retorna la cobertura existe coincidencia nula o falto coincidencia en algun paquetes        
          else if(same_coverages.length != 0){

            // console.log("same_coverages:")
            // console.log(same_coverages)
            // console.log(coverages)

            var prices = []

            for (let i = 0; i < packagesData.length; i++) {
              prices.push({
                package_name: '-', 
                coverage_name: '-', 
                insured_amount: "-", 
                covered_type: 'Cantidad', 
                insured_detail: ''
              })

            }

            same_coverages.forEach(same_coverage => {
              var index = same_coverage.index            
              prices[index] = same_coverage
            })

            response.push({
              coverage: {
                description:coverage_name,
                prices:prices
              }
            })

          }

          same_coverages.forEach(same_coverage => {
              coverages =  coverages.filter( (coverage) => coverage != same_coverage)
          })

        })

        return response
      }
    },
  }
</script>