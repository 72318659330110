var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"ProductPackageItem_body"}},[(_vm.productItemLocal)?_c('div',[_c('table',{attrs:{"id":"tbl_packages"}},[_c('tr',[_c('th',[(_vm.productItemLocal)?_c('div',[(_vm.productItemLocal.id != 7)?_c('div',{attrs:{"id":"more_about"},on:{"click":_vm.openManual}},[_vm._v("Saber Más")]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"extra_message"}},[(_vm.productItemLocal.id == 5)?_c('h1',[_vm._v("Copago")]):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"title_package"},[_vm._v("\n              "+_vm._s(_vm.productItemLocal.name)+"\n            ")]),_vm._v(" "),_c('lottie-animation',{staticClass:"lottie",attrs:{"path":_vm.productItemLocal.path_animation_json}}),_vm._v(" "),(_vm.productItemLocal.id != 7)?_c('h1',{staticClass:"text-tertiary"},[_vm._v("Pago Único Anual")]):_vm._e()],1):_vm._e()]),_vm._v(" "),_vm._l((_vm.productItemLocal.products),function(cardData,index){return _c('th',{key:index + '-data'},[_c('div',{staticClass:"thead_container"},[(_vm.productItemLocal.id === 12)?_c('div',{staticClass:"insurance12"},[_c('div',{class:[
                  _vm.productItemLocal.packageSelectedId == cardData.id
                    ? 'text-tertiary'
                    : 'text-gray-300' ]},[_c('h1',[_vm._v("\n                  "+_vm._s(cardData.name)+"\n                ")]),_vm._v(" "),_c('h1',[_vm._v("Generales")]),_vm._v(" "),(_vm.productItemLocal.products[index].id == 4 || _vm.productItemLocal.products[index].id == 5 || _vm.productItemLocal.products[index].id == 6 )?_c('h1',[_vm._v(" $ "+_vm._s(_vm.numberWithCommas(cardData.policy_premium))+" USD ")]):_c('h1',[_vm._v(" $ "+_vm._s(_vm.numberWithCommas(cardData.policy_premium))+" MXN ")])])]):_c('div',{class:[
                _vm.productItemLocal.packageSelectedId == cardData.id
                  ? 'text-tertiary'
                  : 'text-gray-300' ]},[_c('h1',[_vm._v("\n                "+_vm._s(cardData.name)+"\n              ")]),_vm._v(" "),_c('h1',[_vm._v("\n                $ "+_vm._s(_vm.numberWithCommas(cardData.policy_premium))+" MXN\n              ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.productItemLocal.packageInCar),expression:"!productItemLocal.packageInCar"}],staticClass:"\n                checkbox\n                border-2\n              ",class:[
                _vm.productItemLocal.packageSelectedId == cardData.id
                  ? ''
                  : '' ],on:{"click":function($event){_vm.productItemLocal.packageSelectedId = cardData.id;
                _vm.updateOption(cardData.id);}}},[_c('check-button',{key:'check' + _vm.productItem.id + '-' + index,class:[
                  _vm.productItemLocal.packageSelectedId == cardData.id
                    ? 'cursor-pointer'
                    : '' ],attrs:{"item":_vm.productItemLocal.id,"is_selected":_vm.productItemLocal.packageSelectedId == cardData.id ? true : false}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productItemLocal.packageInCar),expression:"productItemLocal.packageInCar"}],staticClass:"\n                checkbox_in_car\n                border-2\n              ",class:[
                _vm.productItemLocal.packageSelectedId == cardData.id
                  ? 'border-grey-200'
                  : 'border-grey-200' ]},[_c('check-button',{key:'check' + _vm.productItemLocal.id + '-' + index,class:[
                  _vm.productItemLocal.packageSelectedId == cardData.id
                    ? 'cursor-pointer'
                    : 'cursor-not-allowed' ],attrs:{"item":_vm.productItemLocal.id,"is_selected":_vm.productItemLocal.packageSelectedId == cardData.id ? true : false}})],1),_vm._v(" "),(_vm.productItemLocal.packageSelectedId == cardData.id)?_c('div',{staticClass:"btn_add_car"},[(!_vm.productItemLocal.packageInCar && _vm.productItemLocal)?_c('button',{staticClass:"btn_item",on:{"click":function($event){_vm.productItemLocal.packageInCar = true;
                _vm.addToShoopingCar(_vm.productItemLocal);
                _vm.animateRemove(_vm.productItemLocal);
                _vm.clickBacklogInsuranceAceptation(_vm.productItemLocal);}}},[_vm._v("\n                Añadir al carrito\n              ")]):_c('div',[_c('h4',[_vm._v("Item Agregado")]),_vm._v(" "),_c('button',{staticClass:"btn_item",on:{"click":function($event){_vm.productItemLocal.packageInCar = false;
                    _vm.removeFromShoopingCar(_vm.productItemLocal);}}},[_vm._v("\n                  Remover item\n                ")])])]):_vm._e()])])})],2),_vm._v(" "),_vm._l((_vm.serializeComparisonPackages(_vm.productItemLocal.products)),function(cardData,index){return _c('tr',{key:index + '-data'},[_c('td',[_vm._v(_vm._s(cardData.coverage.description))]),_vm._v(" "),_vm._l((cardData.coverage.prices),function(price,index){return _c('td',{key:index + '-data'},[(['Cantidad', 1].includes(price.covered_type) && isNaN(price.insured_amount))?_c('div',[_vm._v("\n            "+_vm._s(price.insured_amount)+"\n          ")]):(['Cantidad', 1].includes(price.covered_type) && !isNaN(price.insured_amount))?_c('div',[_vm._v("\n              $"+_vm._s(_vm.numberWithCommas(price.insured_amount))+"\n          ")]):(price.covered_type == 'Total')?_c('div',[_vm._v("\n            AMPARADA\n          ")]):(price.covered_type == 'Excluido')?_c('div',[_vm._v("\n            NO AMPARADA\n          ")]):_vm._e(),_vm._v(" "),(price.covered_type == 'Otro')?_c('div',{staticClass:"text-sm flex-1 text-center leading-4"},[_vm._v("\n            "+_vm._s(price.insured_detail)+"\n          ")]):_vm._e()])})],2)}),_vm._v(" "),_vm._m(0)],2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('div',{staticClass:"just_space"})])}]

export { render, staticRenderFns }