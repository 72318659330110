<template>
  <div class="relative">
    <lottie
      :options="checkAnimation.defaultOptions"
      :height="40"
      :width="40"
      @animCreated="handleAnimation"
      @data_ready="data_ready"
      :key="'check' + item.id"
    />
  </div>
</template>

<script>
import Lottie from "./lottie";
import checkData from "../assets/animations/check.json";

export default {
  name: "CheckButton",
  data: function() {
    return {
      checkAnimation: {
        totalFrames: 93,
        active: false,
        defaultOptions: {
          autoplay: false,
          loop: false,
          animationData: checkData
        },
        animationSpeed: 1
      }
    };
  },
  components: {
    Lottie
  },
  props: ["item", "is_selected"],
  computed: {},
  mounted() {
    this.prop(this.is_selected);
  },
  methods: {
    data_ready() {
      this.checkAnimation.totalFrames = this.animCheck.totalFrames;
    },
    handleAnimation: function(anim) {
      let self = this;
      self.animCheck = anim;
      self.animCheck.addEventListener("data_ready", function() {
        self.checkAnimation.totalFrames = self.animCheck.totalFrames;
      });
    },
    prop(value) {
      let self = this;
      if (!value) {
        self.animCheck.playSegments(
          [self.checkAnimation.totalFrames, 60],
          true
        );
      } else {
        self.animCheck.playSegments(
          [60, self.checkAnimation.totalFrames],
          true
        );
      }
    }
  },
  watch: {
    is_selected: function(newVal) {
      this.prop(newVal);
    }
  }
};
</script>

<style scoped ></style>
