<template>

  <div id="FilteredProductsPage_body">

    <div id="container_packages">
      
      <!--? Logo-Mascota que aparece unos cuantos segundos -->
      <div id="logo_pet">
        <div
          v-for="item in selectedProductCategories"
          :key="item.id"
          class="previewed-products"
        >
          <img :src="item.image_xs_primary"/>
        </div>
      </div>

      <div class="container_card_package">
        <template v-for="item in detailFilteredProducts">
          <product-package-item
            v-if="item.products.length"
            class="product-package-item"
            :productItem="item"
            :key="item.id"
            @update-option="updateSelected"
          ></product-package-item>
        </template>
      </div>

    </div>



    <!--Si es que no se encontraron productos-->
    <div id="not_found_packages"
      v-if="!thereAreProducts"
    >
      <!--Parece que ha habido un error al cotizar los productos, aún puedes cotizar otros productos.-->
      No obtuvimos respuesta de la aseguradora para cotizar tus productos, sin
      embargo, aún puedes cotizar otros seguros.
    </div>
    
    
    <!--Si el cliente selecciona al menos un producto-->
    <div id="btn_buy_products">
      <div
        class="btn_container"
        v-if="thereAreProducts"
      >
        <button
          @click="next()"
          :disabled="!canContinue"
          :class="[
            canContinue
              ? ''
              : 'hidden'
          ]"
          style="opacity: 1;"
        >
          Quiero los productos del carrito
        </button>
      </div>

      <!--Muestra botón volver al inicio-->
      <!-- <div v-else>
        <router-link
          to="/our-services"
          class="nav-link py-2 px-5 rounded-md text-primary bg-special-2 hover:bg-tertiary transition duration-600 pop"
          >Volver al inicio</router-link
        >
      </div> -->
    </div>
    
    <div>{{ error }}</div>
    
  </div>

</template>

<script>

import { mapMutations, mapGetters } from "vuex";
import ProductPackageItem from "@/components/ProductPackageItem/ProductPackageItem.vue";

export default {
  name: "FilteredProductsPage",
  data: function() {
    return {
      error: "",
      packagesList: [],
      copyFilteredProducts: [],
      detailFilteredProducts: []
    };
  },
  components: {
    ProductPackageItem
  },
  computed: {
    ...mapGetters(["shoppingCar"]),
    canContinue() {
      return Boolean(this.shoppingCar.getItems().length);
    },
    selectedProductCategories() {
      return this.$store.state.BuyProcessStore.productCategories.userSelection;
    },
    filteredProducts() {
      return this.$store.state.BuyProcessStore.filteredProducts;
    },
    thereAreProducts() {
      let response = false;
      if (this.detailFilteredProducts.length) {
        this.detailFilteredProducts.forEach(productCategory => {
          if (productCategory.products.length) {
            response = true;
          }
        });
      }

      return response;
    }
  },
  mounted() {
    let self = this;
    self.detailFilteredProducts = self.selectedProductCategories.map(x => {
      let auxProducts = self.filteredProducts.filter(
        item => item.category_id == x.id
      );
      return {
        ...JSON.parse(JSON.stringify(x)),
        products: auxProducts,
        packageSelectedId: auxProducts.length
          ? auxProducts[0].id
          : null /* First selection by default */,
        packageInCar: false
      };
    });

    self.$nextTick(() => {
      self.entryAnimation();
    });
  },
  destroyed() {},
  methods: {
    ...mapMutations(["updateShoppingList"]),
    entryAnimation() {
      let self = this;
      let delayBetween = 200;
      let iniDelay = 1000;
      self.$anime
        .timeline()
        .add({
          targets: ".previewed-products",
          scale: [1, 0],
          opacity: [1, 0],
          easing: "easeOutExpo",
          duration: delayBetween * 2,
          delay: (el, i) => delayBetween * i + iniDelay
        })
        .add(
          {
            targets: ".product-package-item",
            translateX: [-500, 0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: delayBetween * 2,
            delay: (el, i) => delayBetween * i + iniDelay
          },
          "-=" +
            ((self.detailFilteredProducts.length + 2) * delayBetween -
              delayBetween / 2)
        );
    },
    updateSelected(data) {
      for (let index = 0; index < this.detailFilteredProducts.length; index++) {
        if (this.detailFilteredProducts[index].id == data.id) {
          this.detailFilteredProducts[index] = Object.assign(
            {},
            this.detailFilteredProducts[index],
            data
          );
          //console.log(this.detailFilteredProducts[index].packageSelectedId);
        }
      }
    },
    resetVariables() {},
    setInitialState() {
      this.resetVariables();
      // Eliminamos la imagen de background principal, esto habilita el video
      this.$store.commit("updateBgApp", "");
    },
    next() {
      if (this.shoppingCar.getItems().length) {
        this.$router.push({ name: "user-register", params: {} });
      }
    },
    removeFromShoopingCar(item) {
      this.shopingItems.push(item);
      this.updateShoppingList(this.shopingItems);
    },
    addToShoopingCar(item) {
      this.shopingItems.push(item);
      this.updateShoppingList(this.shopingItems);
    }
  }
};
</script>